var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var DataRepairImportService = /** @class */ (function () {
    function DataRepairImportService() {
    }
    /**
     * 村委资料查询
     */
    DataRepairImportService.prototype.import = function (data, loading) {
        return this.netService.send({
            server: repairService.dataRepairImportController.import,
            data: data,
            loading: loading
        });
    };
    /**
   * 查询通讯类型
   */
    DataRepairImportService.prototype.getDataTypes = function (data, loading) {
        return this.netService.send({
            server: repairService.dataRepairImportController.getDataTypes,
            data: data,
            loading: loading
        });
    };
    /**
     *导入比较
     */
    DataRepairImportService.prototype.importCompare = function (data, loading) {
        return this.netService.send({
            server: repairService.dataRepairImportController.importCompare,
            data: data,
            loading: loading
        });
    };
    /**
   *下载模板
   */
    DataRepairImportService.prototype.getSystemTemplate = function (data, loading) {
        return this.netService.send({
            server: repairService.dataRepairImportController.getSystemTemplate,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], DataRepairImportService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DataRepairImportService.prototype, "import", null);
    return DataRepairImportService;
}());
export { DataRepairImportService };
