var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import DataBox from '~/components/common/data-box.vue';
import { KosekiDataService } from '~/services/repair-service/koseki-data.service';
import { KosekiRemarkService } from '~/services/repair-service/koseki-remark.service';
import { RelationshipService } from '~/services/repair-service/relationship.service';
import { SocialSecurityDataService } from '~/services/repair-service/social-security-data.service';
import { VillageCommitteeDataService } from '~/services/repair-service/village-committee-data.service';
import { CommunicationDataService } from '~/services/repair-service/communication-data.service';
import { SpecialTransferDataService } from '~/services/repair-service/special-transfer-data.service';
import { dataRepair } from '~/config/enum.config';
var ImportBatchDeletion = /** @class */ (function (_super) {
    __extends(ImportBatchDeletion, _super);
    function ImportBatchDeletion() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.derateDataSet = [];
        return _this;
    }
    ImportBatchDeletion.prototype.close = function () { };
    ImportBatchDeletion.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.kosekiDataService.searchGroupResult(this.docName, this.loading)
            .subscribe(function (data) {
            _this.derateDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    ImportBatchDeletion.prototype.deleteWork = function (row) {
        var _this = this;
        var server;
        switch (this.docName) {
            case dataRepair.kosekiData:
                server = this.kosekiDataService;
                break; //户籍资料
            case dataRepair.kosekiRemark:
                server = this.kosekiRemarkService;
                break; //户籍备注
            case dataRepair.relationship:
                server = this.relationshipService;
                break; //关联关系
            case dataRepair.socialSecurityData:
                server = this.socialSecurityDataService;
                break; //社保资料
            case dataRepair.villageCommitteeData:
                server = this.villageCommitteeDataService;
                break; //村委资料
            case dataRepair.communicationData:
                server = this.communicationDataService;
                break; //通讯资料
            case dataRepair.specialTransferData:
                server = this.specialTransferDataService;
                break; //特调计生资料
            default: break;
        }
        server.deleteByBatch(row.batchNumber, this.loading)
            .subscribe(function (data) {
            _this.$message.success('删除成功！');
            _this.close();
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(KosekiDataService)
    ], ImportBatchDeletion.prototype, "kosekiDataService", void 0);
    __decorate([
        Dependencies(KosekiRemarkService)
    ], ImportBatchDeletion.prototype, "kosekiRemarkService", void 0);
    __decorate([
        Dependencies(RelationshipService)
    ], ImportBatchDeletion.prototype, "relationshipService", void 0);
    __decorate([
        Dependencies(SocialSecurityDataService)
    ], ImportBatchDeletion.prototype, "socialSecurityDataService", void 0);
    __decorate([
        Dependencies(VillageCommitteeDataService)
    ], ImportBatchDeletion.prototype, "villageCommitteeDataService", void 0);
    __decorate([
        Dependencies(CommunicationDataService)
    ], ImportBatchDeletion.prototype, "communicationDataService", void 0);
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], ImportBatchDeletion.prototype, "specialTransferDataService", void 0);
    __decorate([
        Emit('close')
    ], ImportBatchDeletion.prototype, "close", null);
    __decorate([
        Prop({ required: true, default: '' })
    ], ImportBatchDeletion.prototype, "docName", void 0);
    ImportBatchDeletion = __decorate([
        Component({
            components: {
                DataBox: DataBox,
            },
        })
    ], ImportBatchDeletion);
    return ImportBatchDeletion;
}(Vue));
export default ImportBatchDeletion;
