var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { repairService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var CommunicationDataService = /** @class */ (function () {
    function CommunicationDataService() {
    }
    /**
     * 通讯资料查询
     */
    CommunicationDataService.prototype.search = function (data, page, sort, loading) {
        return this.netService.send({
            server: repairService.communicationDataController.search,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 通讯资料创建
     */
    CommunicationDataService.prototype.insert = function (data, loading) {
        return this.netService.send({
            server: repairService.communicationDataController.insert,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除通讯资料
     */
    CommunicationDataService.prototype.delete = function (data, loading) {
        return this.netService.send({
            server: repairService.communicationDataController.delete,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改通讯资料
     */
    CommunicationDataService.prototype.update = function (data, loading) {
        return this.netService.send({
            server: repairService.communicationDataController.update,
            data: data,
            loading: loading
        });
    };
    /**
     * 按导入批次删除户籍资料
     */
    CommunicationDataService.prototype.deleteByBatch = function (batchNumber, loading) {
        return this.netService.send({
            server: repairService.communicationDataController.deleteByBatch,
            data: {
                batchNumber: batchNumber
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], CommunicationDataService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CommunicationDataService.prototype, "search", null);
    return CommunicationDataService;
}());
export { CommunicationDataService };
