var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import { NetService } from "~/utils/net.service";
import { commonService } from "~/config/server";
import app from "~/config/app.config";
var RepairFileUpload = /** @class */ (function (_super) {
    __extends(RepairFileUpload, _super);
    function RepairFileUpload() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // 上传成功回调事件
    RepairFileUpload.prototype.onUploadSucess = function (id, data) { };
    // 上传进度回调事件
    RepairFileUpload.prototype.onUploadProgress = function (id, percent) { };
    // 上传异常回调事件
    RepairFileUpload.prototype.onUploadError = function (id, error) { };
    // 更新文件列表
    RepairFileUpload.prototype.updateFileList = function (fileList) { };
    //删除成功的回调事件
    RepairFileUpload.prototype.onUploadRemove = function () { };
    Object.defineProperty(RepairFileUpload.prototype, "headers", {
        // 获取上传文件头部信息
        get: function () {
            return {
                "X-UserToken": this.userToken
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RepairFileUpload.prototype, "disableUpload", {
        /**
         * 是否禁止上传
         */
        get: function () {
            return this.fileNumberLimit
                ? this.fileList.length >= this.fileNumberLimit
                : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RepairFileUpload.prototype, "otherData", {
        get: function () {
            var data = null;
            if (this.idNo !== undefined) {
                data = "{'{idCard}':'" + this.idNo + "'}";
                data = { "jsonObj": data };
            }
            return data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RepairFileUpload.prototype, "uploadUrl", {
        /**
         * 上传路径
         */
        get: function () {
            return [
                app.url.server,
                NetService.generateRequestUrl(commonService.repairUploadController.upload)
            ].join("/");
        },
        enumerable: false,
        configurable: true
    });
    RepairFileUpload.prototype.clickHandle = function (event) {
        if (this.disableUpload) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    /**
     * 上传成功回调
     */
    RepairFileUpload.prototype.onSucess = function (data, _a) {
        var uid = _a.uid;
        this.onUploadSucess(uid, data);
    };
    /**
     * 上传进度回调
     */
    RepairFileUpload.prototype.onProgress = function (_a, _b) {
        var percent = _a.percent;
        var uid = _b.uid;
    };
    /**
     * 错误信息回调
     */
    RepairFileUpload.prototype.onError = function (err, file) {
        this.onUploadError(err, file);
    };
    /**
     * 文件上传状态改变回调
     */
    RepairFileUpload.prototype.onChange = function (file, uploadFileList) {
        switch (file.status) {
            case "ready": {
                this.fileList.push({
                    uid: file.uid,
                    name: file.name,
                    percentage: file.percentage,
                    status: file.status
                });
                break;
            }
        }
        this.updateFileList(this.fileList);
    };
    /**
     * 删除处理
     */
    RepairFileUpload.prototype.onRemove = function (file, fileList) {
        this.onUploadRemove();
        this.updateFileList(fileList);
    };
    /**
     * 删除预处理
     */
    RepairFileUpload.prototype.onBeforeRemove = function (file, fileList) {
        if (file.percentage === 0) {
            var list = fileList.filter(function (x) { return x.uid !== file.uid; });
            this.updateFileList(list);
            return false;
        }
        return this.$confirm("是否确定删除该文件?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        });
    };
    /**
     * 上传文件预处理
     * 上传文件大小限制
     */
    RepairFileUpload.prototype.onBeforeUpload = function (_a) {
        var name = _a.name, size = _a.size, uid = _a.uid;
        if (this.AllowExtension && this.AllowExtension.length) {
            // 文件名异常
            if (name.toString().split(".") < 2) {
                this.$message("\u6587\u4EF6\u683C\u5F0F\u9519\u8BEF");
                return false;
            }
            var extension = name.split(".").slice(-1)[0];
            if (!this.AllowExtension.includes(extension)) {
                this.$message("\u6587\u4EF6\u683C\u5F0F\u4E0D\u7B26\u5408\u8981\u6C42");
                return false;
            }
        }
        // 限制上传文件大小
        if (this.fileSizeLimit * 1024 * 1024 < size) {
            this.$message("\u6587\u4EF6" + name + "\u8D85\u8FC7\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u9650\u5236" + this.fileSizeLimit + "MB");
            return false;
        }
    };
    RepairFileUpload.prototype.reset = function () {
        var upload = this.$refs["upload"];
        upload.clearFiles();
        this.updateFileList([]);
    };
    __decorate([
        State
    ], RepairFileUpload.prototype, "userToken", void 0);
    __decorate([
        Emit("onUploadSuccess")
    ], RepairFileUpload.prototype, "onUploadSucess", null);
    __decorate([
        Emit("onUploadProgress")
    ], RepairFileUpload.prototype, "onUploadProgress", null);
    __decorate([
        Emit("onUploadError")
    ], RepairFileUpload.prototype, "onUploadError", null);
    __decorate([
        Emit("update:fileList")
    ], RepairFileUpload.prototype, "updateFileList", null);
    __decorate([
        Emit("onUploadRemove")
    ], RepairFileUpload.prototype, "onUploadRemove", null);
    __decorate([
        Prop({ default: true })
    ], RepairFileUpload.prototype, "showFileList", void 0);
    __decorate([
        Prop()
    ], RepairFileUpload.prototype, "disabled", void 0);
    __decorate([
        Prop({
            type: Array
        })
    ], RepairFileUpload.prototype, "AllowExtension", void 0);
    __decorate([
        Prop()
    ], RepairFileUpload.prototype, "idNo", void 0);
    __decorate([
        Prop({
            default: false,
            type: [Boolean, Number]
        })
    ], RepairFileUpload.prototype, "fileNumberLimit", void 0);
    __decorate([
        Prop({
            default: 100,
            type: Number
        })
    ], RepairFileUpload.prototype, "fileSizeLimit", void 0);
    __decorate([
        Prop({
            default: function () { return []; },
            type: Array
        })
    ], RepairFileUpload.prototype, "fileList", void 0);
    RepairFileUpload = __decorate([
        Component({
            components: {}
        })
    ], RepairFileUpload);
    return RepairFileUpload;
}(Vue));
export default RepairFileUpload;
